
import * as localImageSharpRuntime$yf1lUvpUl6 from '/Users/ulysse/Dev/Agence-Prost/nuxt/providers/localImageSharp.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "localImageSharp",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['localImageSharp']: { provider: localImageSharpRuntime$yf1lUvpUl6, defaults: {"baseURL":"https://s48w4wg.51.159.36.139.sslip.io/uploads/"} }
}
        